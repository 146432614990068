import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, mobileHero } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView
            textCenter
            column
            center
            w="100%"
            ph="7%"
            pt="10px"
            pb="5px"
            bg="rgb(0,0,0,.8)"
            style={{
              backdropFilter: 'blur(10px)',
              webkitBackdropFilter: 'blur(10px)',
            }}
          >
            <CFView column center ph="10%">
              <CFText
                color="white"
                style={{
                  fontSize: 25,
                  fontWeight: 900,
                }}
                raleway
              >
                VIBRANT FLAVOURS OF JAPAN
              </CFText>
            </CFView>
            <CFView pulsate mt="5px" mb="10px">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="650px"
          maxWidth="1400px"
          w="auto"
          image={`url(${hero}) 50% -30% / 100% no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
          zIndex={90}
          column
          justifyBetween
          alignCenter
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
        >
          <Header />
          <CFView
            row
            center
            ph="100px"
            maxWidth="1400px"
            bg="rgba(230, 131, 9, 1)"
          >
            <CFText
              color="black"
              style={{
                fontSize: 28,
                fontWeight: 900,
              }}
              raleway
            >
              VIBRANT FLAVOURS OF JAPAN
            </CFText>
            <CFView ml="30px" pt="5px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
